import {HASH_NODE_BLOG_API_BASE_URL, HASH_NODE_USERNAME,MEDIUM_USERNAME} from "../utils/StaticConstant";

const query = `
    {
      user(username: "${HASH_NODE_USERNAME}") {
        publication {
          posts{
            slug
            title
            brief
            coverImage
            totalReactions
            dateAdded
          }
        }
      }
    }
  `;

export const getAllHashNodeBlogs = async () => {
  const response = await fetch(HASH_NODE_BLOG_API_BASE_URL, {
    method: 'POST',
    headers: {
      'Content-type': 'application/json',
    },
    body: JSON.stringify({query}),
  })
  const ApiResponse = await response.json();
  return ApiResponse.data.user?.publication?.posts ?? [];
}
export const getAllMediumBlogs = async () => {
  const response = await fetch(`https://api.rss2json.com/v1/api.json?rss_url=https://medium.com/feed/@${MEDIUM_USERNAME}`)
  const ApiResponse = await response.json();
  return ApiResponse.items ?? [];
}
