export const ExperienceSchema = [
  {
    title: "FullStack Sr. Software Engineer",
    period: "Sep. 2022 - Present",
    company: "BYSL Global TechnologySkill Group, Dhaka, Bangladesh",
    description: [
      "Microservice based architecture",
      "Backend API service is developed with NodeJs and Laravel",
      "Frontend is developed with MUI React",
      "Deploy the web app using a Docker web server",
      "Configuring Keycloak and WSO2 API manager",
      "System log manage using kafka and Elasticsearch, Logstash, and Kibana (ELK stack)",
      `Used: NodeJs, NestJs, React, PHP, Laravel, Lumen, Kafka, MySQL, MongoDb, ElasticSearch, Keycloak, Docker, Gitlab CI, etc`
    ]

  },
  {
    title: "FullStack Assistant Software Engineer",
    period: "Dec. 2020 - Sep. 2022",
    company: "SoftBD Ltd, Dhaka, Bangladesh",
    description: [
      "Microservice based architecture",
      "Backend API service is developed with Lumen",
      "Frontend is developed with NextJs",
      "Real time Notification fetching using socket",
      "Deploy the web app using a Docker web server",
      "WSO2 api manager and identity server",
      "API documentation with Swagger",
      "Distributed transaction using SAGA pattern, RabbitMQ",
      "Distributed notification manage using kafka",
      "Data aggregation using kafka debezium",
      "System log manage using kafka and Elasticsearch, Logstash, and Kibana (ELK stack)",
      "Distributed payment transaction using ekpay, sslcommerz and RabbitMQ",
      `Used: NodeJs, NestJs, React, PHP, Laravel, Lumen, Kafka, MySQL, MongoDb, ElasticSearch, Docker,Gitlab CI, etc`
    ]
  },
  {
    title: "Junior Software Engineer",
    period: "Jan. 2019 - Nov. 2020",
    company: "Bemantech Ltd., Dhaka, Bangladesh",
    description: [
      "Maintenance and feature development",
      "Implementation of Banglalink Network API using cURL",
      "Integration ekpay, sslcommerz",
      "SMS api integration",
      `Used: NodeJs, NestJs, React, PHP, Laravel, CakePhp, JQuery, Gitlab CI, etc`
    ]
  }

]
