import {Col, Container, Row} from "react-bootstrap";
import {useEffect, useState} from "react";
import {getAllMediumBlogs} from "../services/BlogPostService";
import MediumBloogItem from "../components/MediumBlogItem"
import BlogLoading from "../components/BlogLoading";

const BlogPosts = () => {
  const [blogPosts, setBlogPosts] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    const load = async () => {
      //await hashNodeBlogPosts()
      await fetchMediumBlogs();
    }
    load();
  }, [])

  // const hashNodeBlogPosts = async () => {
  //   setIsLoading(true)
  //   const hashNodeBlogs = await getAllHashNodeBlogs();
  //   setBlogPosts(hashNodeBlogs);
  //   setIsLoading(false)
  // }

  const fetchMediumBlogs = async () => {
    setIsLoading(true)
    const hashNodeBlogs = await getAllMediumBlogs();
    setBlogPosts(hashNodeBlogs);
    setIsLoading(false)
  }

  return (
    <Container>
      <Row>
        <Col className="mt-5">
          <Row>
            <Col md={4} xl={4} sm={12}>
              <h1>Blog Posts</h1>
            </Col>
            <Col md={8} xl={8} sm={12}>
              {
                isLoading ? <BlogLoading isLoading={isLoading}/> :
                  blogPosts.map((item, index) =>
                    <MediumBloogItem post={item} index={index}/>
                  )
              }
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  )
}

export default BlogPosts
