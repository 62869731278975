import {SiFacebook, SiGithub, SiGitlab, SiHashnode, SiLinkedin} from "react-icons/si";
import {BsMedium} from "react-icons/bs";
import avatarImage from "../assets/img/avater.jpeg"
import piyalImage from "../assets/img/piyal-for-cv.png"

export const BasicInfoShema = {
  profile: {
    name: "Md Tasmidur Rahman",
    occupation: "FullStack Software Engineer",
    location: "Bysl global technologies limited plot 39/2, kalachandpur (North Baridhara), Gulshan, Dhaka 1212, Bangladesh",
    image: piyalImage ?? avatarImage,
  },
  contact:
    {
      email: "tasmidurrahman@gmail.com",
      mobileNumber: "+880 1767111434",
      address: `Khilkhet, Dhaka Bangladesh`
    },
  aboutMe: {

    description: `
             <div>
  <h2>Hi,I'm Tasmidur</h2>
  <br/>
  <p>Experienced Software Engineer specializing in the development of robust and scalable systems within dynamic tech environments. Currently driving innovation at BYSL Global Technology Group in Bangladesh, where my focus lies in implementing a microservices architecture. Proficient in crafting backend API services using Laravel and NodeJS, alongside integrating payment gateways such as Stripe and SSLCommerz. My expertise extends to creating responsive frontends with ReactJs and deploying web applications efficiently using Docker.</p>

  <p>In the realm of authentication and API management, I have successfully configured Keycloak and WSO2. Additionally, I manage system logs effectively utilizing the ELK stack (Elasticsearch, Logstash, Kibana) and implement Kafka for streamlined log management. My tech stack includes Laravel, PHP, ReactJs, Kafka, MySQL, MongoDB, Elasticsearch, Keycloak, Docker, GitHub CI, among others.</p>
  
  <p>In my previous role as an Assistant Software Engineer at Soft BD Ltd., I honed my skills in microservices, utilizing Lumen for backend API development and NextJs for frontend design. Real-time notifications were facilitated through socket communication, and I excelled in deploying web applications using Docker. My responsibilities extended to working with WSO2 API manager and identity server, implementing Swagger for API documentation, and handling distributed transactions using the SAGA pattern and RabbitMQ. The integration of Kafka for distributed notification management and data aggregation using Kafka Debezium showcased my versatility. My proficiency spanned PHP, Lumen, ReactJs, Kafka, MySQL, MongoDB, Elasticsearch, Docker, Gitlab CI, and more.</p>
  
  <p>As a Jr. Software Engineer at Bemantech Ltd., I contributed to maintenance and feature development, implementing Cellular Network APIs, integrating payment gateways, and managing SMS gateways. Technologies such as NodeJs, ReactJs, PHP, Laravel, CakePHP, JQuery, and Gitlab CI were part of my toolkit.</p>
  
  <p>I am passionate about staying at the forefront of technological advancements and continuously enhancing my skills to drive innovative solutions in the ever-evolving software landscape.</p>
</div>
    `
  },
  academic: [
    {
      career: "B.Sc Engineering in Information and Communication Technology (ICT)",
      passingYear: "2018",
      institution: "Mawlana Bhashani Science TechnologySoftSkill University",
    },
  ],
  interest: [
    "Open Source Software",
    "Travelling",
    "Cloud Computing"
  ],
  skills: {
    technicalLabel: "Technologies",
    softLabel: "Skills",
    technicalSkills: [
      "Nodejs",
      "Nestjs",
      "React",
      "PHP",
      "Laravel",
      "MySql",
      "MongoDb",
      "ElasticSearch",
      "Git",
    ],
    softSkills: [
      "WSO2 Api Manager",
      "KeyCloak",
      "Docker",
      "ELK",
      "Kafka",
      "Kafka-debezium",
      "RabbitMq"
    ],
  },
  socialMedia: {
    label: "SOCIAL",
    social: [
      {
        label: "Medium",
        name: "hashnode",
        url: "https://tasmidur.medium.com/",
        icon: <BsMedium size={25} fill="bg-secondary"/>
      },
      {
        label: "HashNode",
        name: "hashnode",
        url: "https://tasmidur.hashnode.dev/",
        icon: <SiHashnode size={25}/>
      },
      {
        label: "LinkedIn",
        name: "linkedin",
        url: "https://www.linkedin.com/in/tasmidur",
        icon: <SiLinkedin size={25}/>
      },
      {
        label: "GitHub",
        name: "github",
        url: "https://github.com/tasmidur",
        icon: <SiGithub size={25} fill="bg-secondary"/>,
      },
      {
        label: "GitLab",
        name: "gitlab",
        url: "https://gitlab.com/tasmidur",
        icon: <SiGitlab size={25} fill="#FC6D27"/>,
      },
      {
        label: "FaceBook",
        name: "facebook",
        url: "https://www.facebook.com/profile.php?id=100008228165258",
        icon: <SiFacebook size={25}/>
      },
    ],
  }
};
